<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <div class="row w-100 mx-0">
          <div class="col-lg-4 mx-auto">
            <div class="auth-form-light text-left py-5 px-4 px-sm-5">
              <div class="brand-logo" style="display: flex; justify-content: center">
                <img src="../../public/images/logo3.png" alt="logo" style="width: 30%" />
              </div>
              <h4 style="font-weight:bolder">Sign Up</h4>
              <form class="pt-3" @submit.prevent="register">
                <div class="alert alert-warning alert-dismissible fade show" role="alert"
                  v-if="response.status == '03'">
                  <strong>{{ response.message }}</strong>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="alert alert-success alert-dismissible fade show" role="alert"
                  v-if="response.status == '00'">
                  <strong>{{ response.message }}</strong>
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input type="text" class="form-control form-control-lg" name="fullName" id="fullname"
                      placeholder="Full name" v-model="fullName" style="color: #242323" />
                    <label class="error  text-danger" v-if="errors.fullName">{{ errors.fullName }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input type="email" class="form-control form-control-lg" name="email" id="email" placeholder="Email"
                      v-model="email" style="color: #242323" />
                    <label class="error text-danger" v-if="errors.email">{{ errors.email }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input type="text" class="form-control form-control-lg" name="companyName"
                      placeholder="Institution/Company name" v-model="companyName" style="color: #242323" />
                    <label class="error  text-danger" v-if="errors.companyName">{{ errors.companyName }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <select class="form-control form-control-lg" v-model="businessCategory" style="color: #242323;border: 1px solid #424351;" >
                      <option value="" disabled selected>Select Category of Business</option>
                      <option value="small">Personal/Small Scale Business</option>
                      <option value="medium">Medium Scale Business</option>
                      <option value="enterprise">Enterprise Business</option>
                    </select>
                    <label class="error text-danger" v-if="errors.businessCategory">{{ errors.businessCategory }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <div class="form-check form-check-flat form-check-secondary">
                      <label class="form-check-label   font-weight-bold" style="font-size: 0.9rem; color: #777; padding-left: 5px;">
                        <input type="checkbox" class="form-check-input" v-model="isDeveloper" />
                        I am a Developer
                        <i class="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <SelectComponent @countrySelected="countrySelected" />
                    <label class="error  text-danger" v-if="errors.country">{{ errors.country }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <vue-tel-input :value="mobileNumber" @input="onInput" style="color:black"
                      class="form-control form-control-lg"></vue-tel-input>
                    <label class="error  text-danger" v-if="errors.mobileNumber">{{ errors.mobileNumber }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <input type="password" class="form-control form-control-lg" name="password" placeholder="Password"
                      v-model="password" style="color: #242323" />
                    <label class="error text-danger" v-if="errors.password">{{ errors.password }}</label>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-12">
                    <div class="form-check form-check-flat form-check-secondary">
                      <label class="form-check-label font-weight-bold" style="font-size: 0.9rem; color: #777; padding-left: 5px;">
                        <input type="checkbox" class="form-check-input" v-model="termsAgreed" />
                        I Agree to Terms & Conditions
                        <i class="input-helper"></i>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group row mt-0">
                  <div class="col-sm-12">
                    <button style="background-color: #1e1e2f; border: none"
                      class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-white"
                      type="submit">
                      SIGN UP
                      <span class="circle-loader" style="display: none" id="loader"></span>
                    </button>
                  </div>
                </div>
                <div class="text-center font-weight-strong">
                  Already have an account?
                  <router-link to="/login" class="text-black font-weight-bold">Login</router-link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
</template>
<script>
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import SelectComponent from '@/components/countrySelectComponent'

import baseUrl from "@/shared/baseUrl";
export default {
  components: {
    VueTelInput,
    SelectComponent
  },
  data() {
    return {
      fullName: "",
      email: "",
      mobileNumber: "",
      companyName: "",
      country: "",
      password: "",
      msg: "",
      response: {},
      isDeveloper:false,
      businessCategory:"",
      termsAgreed: false,
      errors: {
      },
    };
  },
  methods: {
    countrySelected(country) {
      this.country = country;
    },
    register() {
      $("#loader").show();
      const user = {
        fullName: this.fullName,
        email: this.email,
        mobileNumber: this.mobileNumber,
        companyName: this.companyName,
        country: this.country,
        password: this.password,
        isDeveloper: this.isDeveloper,
        businessCategory: this.businessCategory
      };

      if (!this.validate()) {
        this.response.message = "Please complete all fields correctly.";
        this.response.status = "03";
        $("#loader").hide();
      } else {
        const vm = this;
        baseUrl
          .post("/signup", user)
          .then((response) => {
            $("#loader").hide();
            this.response = response.data;
            if (response.data.status == "00") {
              this.email = "";
              this.password = "";
              this.fullName = "";
              this.mobileNumber = "";
              this.companyName = "";
              this.country = "";
              this.businessCategory="";
              this.isDeveloper=false;
              setTimeout(function () {
                vm.$router.push("/get-started");
              }, 3000);
            } else {
              this.response.message = response.data.message;
              this.password = "";
            }
          })
          .catch((error) => {
            this.response.message=error;
            $("#loader").hide();
          });
      }
    },
    onInput(mobileNumber, phoneObject, input) {
      if (phoneObject && phoneObject.number) {
        this.mobileNumber = phoneObject.number;
      }
    },
    validate() {
      this.errors = {};

      if (!this.fullName) {
        this.errors.fullName = "Full name is required.";
      }
      if (!this.email) {
        this.errors.email = "Email is required.";
      } else if (!this.isValidEmail(this.email)) {
        this.errors.email = "Email is not valid.";
      }
      if (!this.mobileNumber) {
        this.errors.mobileNumber = "Mobile number is required.";
      }
      if (!this.companyName) {
        this.errors.companyName = "Company name is required.";
      }
      if (!this.country) {
        this.errors.country = "Country is required.";
      }
      if (!this.password) {
        this.errors.password = "Password is required.";
      }
      if (!this.businessCategory) {
        this.errors.businessCategory = "Business Category is required.";
      }
      
      return Object.values(this.errors).every(error => error === null);
    },
    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    submitForm() {
      if (this.validate()) {
        // Proceed with form submission
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: "Open Sans", sans-serif !important;
}

#loader {
  margin-left: 10px;
}

.auth form .form-group {
  margin-bottom: 0.8rem;
}
</style>
